/* eslint-disable react/forbid-dom-props */
import { Dialog } from '@headlessui/react';
import React, { useEffect } from 'react';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Image from 'next/future/image';
import { CtaButton } from '@lambda/ui/src/Cta';
import { loadGoogleSigninScript } from '@/lib/google-signin';
import logoWheelleft from '@/public/images/logo_wheel_left.svg';
import logoWheelright from '@/public/images/logo_wheel_right.svg';
import logoMobile from '@/public/images/reebelo_text_logo.svg';
import i18n from '@/settings/i18n';
import useAuth from '@/lib/use-auth';
import AccountIcon from '@/components/accounts/AccountIcon';
import settings from '@/settings';
import SocialLogin from '@/components/accounts/SocialLogin';

const t = i18n.init();
const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email(t`Email must be a valid email`)
      .required(t`Email is a required field`),
    password: yup
      .string()
      .min(6, t`Password must be at least 6 characters`)
      .required(t`Password is a required field`),
  })
  .required();
type Form = yup.Asserts<typeof schema>;

export default function LoginModal(props: {
  open: boolean;
  onClose: (e?: any) => void; // note that an event e can be passed. If it is, the propagation will be stopped to avoid side effects.
}) {
  const auth = useAuth();
  const form = useForm<Form>({ resolver: yupResolver(schema) });

  useEffect(() => {
    loadGoogleSigninScript();
  }, [props.open]);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 flex h-[100vh] w-full items-start justify-center overflow-hidden bg-teal-500 px-5 pt-28 md:items-center md:bg-gray-700/[.5] md:pt-0"
      onClose={props.onClose}
      open={props.open}
    >
      <div className="w-full text-center md:w-auto">
        <Dialog.Overlay className="fixed inset-0 z-0" />

        <button
          onClick={props.onClose}
          type="button"
          className="btn-close absolute top-5 right-5 opacity-100 md:top-4 md:right-4 md:hidden md:h-2 md:w-2"
          aria-label="Close"
        ></button>
        <div className="absolute top-[18px] left-2/4 translate-x-[-50%] md:hidden">
          <Link href="/">
            <a>
              <Image src={logoMobile} loading="eager" alt="reebelo" />
            </a>
          </Link>
        </div>

        <div
          className="relative z-30 mx-auto w-full max-w-[520px] rounded-[5px] bg-white py-5 px-6 shadow-xl md:py-10 md:px-20"
          id="e2e-login-modal"
        >
          <button
            onClick={props.onClose}
            type="button"
            className="btn-close absolute top-5 right-5 hidden opacity-100 md:top-4 md:right-4 md:inline-block md:h-2 md:w-2"
            aria-label="Close"
          ></button>
          <h1 className="mb-2 flex flex-col items-center justify-center text-xl font-bold text-gray-700 md:mb-4 md:flex-row md:text-[1.875rem]">
            <AccountIcon />
            {t`Login to my account`}
          </h1>

          <form onSubmit={form.handleSubmit((input) => auth.login(input))}>
            <div className="flex w-full flex-col">
              <input
                className="my-1 rounded-sm border border-solid border-gray-500  p-3 text-gray-700 outline-1 outline-gray-700"
                placeholder={t`Email`}
                type="email"
                {...form.register('email')}
              />
              {form.formState.errors.email && (
                <div className="text-left text-xs text-red">
                  {form.formState.errors.email.message}
                </div>
              )}
            </div>
            <div className="flex w-full flex-col">
              <input
                className="my-1 rounded-sm border border-solid border-gray-500  p-3 text-gray-700 outline-1 outline-gray-700"
                placeholder={t`Password`}
                type="password"
                {...form.register('password')}
              />
              {form.formState.errors.password && (
                <div className="text-left text-xs text-red">
                  {form.formState.errors.password.message}
                </div>
              )}
            </div>

            <CtaButton
              type="submit"
              className="my-1 w-full p-3 md:py-4"
              loading={auth.loading}
            >
              {t`Login`}
            </CtaButton>
            {!!auth.error && (
              <p className="text-sm text-red">
                {auth.error === 'Unidentified customer'
                  ? t`Incorrect email or password`
                  : ''}
              </p>
            )}
          </form>
          {['reebelo-nz', 'reebelo-au', 'quista'].includes(settings.store) && (
            <SocialLogin />
          )}
          <div className="mt-5 md:mt-8">
            <div className="mb-2 flex justify-center text-xs text-gray-700 md:text-[1rem]">
              {t`New Customer`}?&nbsp;
              <div
                className="text-blue-500 duration-300 ease-in-out"
                onClick={props.onClose}
              >
                <Link href={'/account/register'}>
                  <a id="e2e-login-modal-create-account">{t`Create your account`}</a>
                </Link>
              </div>
            </div>
            <div
              className="flex justify-center text-xs text-gray-700 md:text-[1rem]"
              onClick={props.onClose}
            >
              {t`Lost password`}?&nbsp;
              <Link href={'/account/recover'}>
                <a
                  id="e2e-login-modal-recover-account"
                  className="text-blue-500 duration-300 ease-in-out"
                >
                  {t`Recover password`}
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 h-[60px] w-[120px] md:hidden">
          <Image
            height={104}
            width={208}
            alt="logoWheelleft"
            src={logoWheelleft}
          />
        </div>
        <div className="absolute right-0 bottom-0 h-[140px] w-[90px] leading-[0] md:hidden">
          <Image
            height={392}
            width={241}
            alt="logoWheelright"
            src={logoWheelright}
          />
        </div>
      </div>
    </Dialog>
  );
}
