export function loadGoogleSigninScript() {
  const script = document.createElement('script');

  script.src = 'https://accounts.google.com/gsi/client';
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}
