import Image from 'next/future/image';
import iconAccount from '@/public/icons/menu/account.svg';

export default function AccountIcon() {
  return (
    <div className="mr-0 flex h-[42px] w-[34px] items-center md:mr-2">
      <Image alt="Login" src={iconAccount} className="w-[34px]" />
    </div>
  );
}
