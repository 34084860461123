import { useEffect } from 'react';
import settings from '@/settings/index';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';

export default function SocialLogin() {
  const trackGoogleLogin = () => {
    triggerMixpanelEvent({
      eventName: 'Login with Google',
      props: {},
    });
  };

  useEffect(() => {
    (window as any).trackGoogleLogin = trackGoogleLogin;

    return () => {
      (window as any).trackGoogleLogin = undefined;
    };
  }, []);

  return (
    <>
      <div className="relative my-2 py-4">
        <div className="absolute h-[1px] w-full bg-gray-500 xxs:h-[2px]"></div>
        <div className="absolute top-1/2 left-1/2 w-8 -translate-y-1/2 -translate-x-1/2 bg-white text-center text-xs font-bold xxs:text-sm">
          OR
        </div>
      </div>
      <div
        id="g_id_onload"
        data-client_id={settings.gsi_client_id}
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="/api/login"
        data-auto_prompt="false"
      ></div>
      <section className="flex justify-center py-2">
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangle"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
          data-click_listener="trackGoogleLogin"
        ></div>
      </section>
    </>
  );
}
